.site-layout-background {
  background: #fff !important;
  padding: 0 !important;
  padding-left: 20px;
  display: flex;
  justify-content: flex-end;
}

.site-layout-background-hotel {
  background: #fff !important;
  padding: 0 !important;
  padding-left: 20px;
  display: flex;
  justify-content: space-between;
}

.ant-menu-horizontal {
  background: #fff !important;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.hotel-name {
  font-size: medium;
  font-weight: bold;
  margin-left: 10px;
}

.hotelSearch {
  margin-top: 20px;
}

.left-container {
  margin-left: 20px;
}

.right-container {
  display: flex;
  align-items: center;
  width: 60%;
}

.ant-menu-submenu-title {
  height: 64px !important;
}

.user-name {
  color: #000000;
  margin-left: 5px;
}

.right-sub-menu-title {
  display: flex;
  margin-right: 4;
  align-items: center;
  justify-content: space-between;
}
