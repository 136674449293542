.logo {
  height: 30px;
  margin: 16px;
  margin-left: 24px;
}

.site-layout .site-layout-background {
  background: #fff !important;
  padding: 0 !important;
}

.select-hotel {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 15px;
  margin-right: 10px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.superadmin-button {
  border: 1px solid white !important;
  width: 80%;
}

.select-hotel-div {
  margin-left: 20px;
}

.select-hotel-text {
  color: white !important;
  margin-bottom: 10px !important;
}

.select-hotel-text-bold {
  color: white !important;
  margin-bottom: 10px !important;
  font-weight: 600;
}

.hotelSearch {
  margin-top: -1px !important;
}

.select-hotel-button {
  border-radius: 7px !important;
  height: 50px !important;
  width: 80%;
}

.ant-layout-sider-children .ant-menu-item-selected {
  background-color: #363a41 !important;
}

.site-layout-background {
  background: #fff !important;
  padding: 0 !important;
}

.ant-menu-item .anticon + span,
.ant-menu-submenu-title .anticon + span {
  color: #fff !important;
}

.ant-menu-dark .ant-menu-item,
.ant-menu-dark .ant-menu-item-group-title,
.ant-menu-dark .ant-menu-item > a,
.ant-menu-dark .ant-menu-item > span > a {
  color: #fff !important;
}

.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
  color: #fff !important;
}

.ant-menu-horizontal {
  background: #fff !important;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.right-container {
  display: flex;
  align-items: center;
}

.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 40px !important;
}

.ant-menu-submenu-title {
  height: 40px !important;
}

.user-name {
  color: #000000;
  margin-left: 5px;
}

.right-sub-menu-title {
  display: flex;
  color: #999999;
  margin-right: 4;
}
