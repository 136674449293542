.header-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}
.list-domain {
  margin-bottom: 0px !important;
}
