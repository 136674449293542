.detail-header {
  padding: 20px;
  display: flex;
  align-items: center;
}

.title-section {
  margin-bottom: 24px;
  font-size: 20px;
  font-weight: 600;
}

.title-page {
  margin-left: 24px;
  font-size: 20px;
  font-weight: 600;
}
