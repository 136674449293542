.full-width {
  width: 100%;
}
.detail-header {
  padding: 20px;
}

.ant-card {
  margin: 20px !important;
  border: 1px solid #d9d9d9 !important;
}

.site-card-wrapper {
  margin-top: 0px !important;
}

.detail-tenant-title-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 32px;
}
.detail-tenant-title {
  font-size: 20px;
  font-weight: 600;
  margin-right: 40px;
}

.domain-wrapper {
  display: flex;
  align-items: center;
}

.detail-key {
  font-weight: 600;
}

.align-center {
  display: flex;
  align-items: center;
}

.detail-header {
  padding: 20px;
  display: flex;
  align-items: center;
}

.ant-card-head {
  color: #fff !important;
  background-color: #101010 !important;
}

.ant-card-head-title {
  font-size: 20px;
}
.detail-tenant {
  height: 360px;
}
.detail-admin-tenant {
  height: 360px;
}

.ant-form-item-label > label {
  font-weight: 600 !important;
}
.form-item {
  margin-bottom: 0px !important;
}

.button-wrapper {
  width: 100%;
  text-align: end;
  padding: 20px;
}
