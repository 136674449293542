.form-container {
  padding: 20px !important;
}

.title-form {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 16px;
}

.modal-title-wrapper {
  display: flex;
  align-items: center;
}

.modal-title {
  font-size: 16px;
  font-weight: 600;
  margin-left: 16px;
}

.list-requirement {
  margin-top: 8px;
  margin-left: 16px;
}

.data-wrapper {
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  padding: 16px;
  margin: 0px 24px;
}

.copy-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
